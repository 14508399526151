<template>
  <div class="container-fluid p-0">
    <div class="page-headermb">
      <h2 class="main-content-title">Teams
      </h2>
    </div>
    <div class="card">
      <div class="card-body">
        <h3 class="card-title mb-4">Edit
          Team</h3>
        <el-form ref="form"
                 :model="formData"
                 :rules="rules"
                 class="project-form overflow-hidden"
                 label-position="top">
          <div class="row">
            <div
                class="col-md-6 col-12">
              <el-form-item
                  label="Unique Team Name"
                  prop="name">
                <el-input
                    v-model="formData.name"/>
              </el-form-item>
              <!-- <el-form-item
                  label="Upload Profile Icon"
                  prop="profile_icon">
                  <el-upload
                      class="upload-demo team-icon upload-team-icon"
                      accept="image/*"
                      :auto-upload="false"
                      :on-change="fileChange"
                      :limit="1"
                      action="">
                      <el-button
                          size="small"
                          type="success"
                          round
                          plain>
                          Click
                          to
                          upload
                      </el-button>
                  </el-upload>
              </el-form-item> -->
            </div>
            <div
                class="col-md-6 col-12">
              <el-form-item
                  label="Team Details"
                  prop="details">
                <el-input
                    type="textarea"
                    :rows="2"
                    v-model="formData.details"/>
              </el-form-item>
            </div>
          </div>

          <h3
              class="card-title mb-3 mt-2 pt-3 border-top">
            Team Members</h3>
          <div class="table-responsive">
            <table
                class="table team-member-table">
              <tr>
                <th>Email</th>
                <th>First Name
                </th>
                <th>Last Name</th>
                <th>Organization
                </th>
                <th>Action</th>
              </tr>
              <tr class="border"
                  v-for="(user, key) in formData.users"
                  :key="key">
                <td>
                  <el-form-item
                      :error="user.email_error"
                      class="p-0 m-0">
                    <el-input @blur="remoteMethod($event.target.value, key)" placeholder="Search or Add Email"
                              v-model="user.email"
                              :disabled="formData.team_leader == user.id || authUser.id !== formData.team_leader || !!user.id"/>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                      :error="user.first_name_error"
                      class="p-0 m-0">
                    <el-input
                        :disabled="formData.team_leader == user.id || authUser.id !== formData.team_leader || !!user.id"
                        v-model="user.first_name"/>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                      :error="user.last_name_error"
                      class="p-0 m-0">
                    <el-input
                        :disabled="formData.team_leader == user.id || authUser.id !== formData.team_leader || !!user.id"
                        v-model="user.last_name"/>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                      :error="user.organization_error"
                      class="p-0 m-0">
                    <el-input
                        :disabled="formData.team_leader == user.id || authUser.id !== formData.team_leader || !!user.id"
                        v-model="user.organization"/>
                  </el-form-item>
                </td>
                <td>
                  <el-button
                      v-if="formData.team_leader != user.id && authUser.id === formData.team_leader"
                      type="success"
                      size="mini"
                      icon="el-icon-delete"
                      plain
                      @click.prevent="removeUser(key)">
                  </el-button>
                </td>
              </tr>
            </table>
          </div>
          <el-button type="success"
                     size="mini"
                     v-if="authUser.id === formData.team_leader"
                     icon="el-icon-plus" plain
                     round
                     @click.prevent="addUser">
            Add More
          </el-button>
        </el-form>
        <div
            v-if="authUser.id === formData.team_leader"
            class="d-flex justify-content-end">
          <el-button class="mt-4"
                     type="success"
                     :disabled="loadingDisabled"
                     :loading="loading"
                     @click.prevent="handleSubmit('form')">
            {{ buttonText }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";
import {EventBus} from "../../event-bus";
import {mapGetters} from "vuex";
import Vue from "vue";

export default {
  name: "TeamEdit",
  data() {
    return {
      formData: {},
      rules: {
        name: [
          {required: true, message: "Please input team name"},
          {min: 3, max: 50, message: "Team name must be 3 to 50 characters"},
        ],
        details: [
          {required: true, message: "Please input team details"},
          {
            min: 3,
            max: 500,
            message: "Team details must be 3 to 500 characters",
          },
        ],
        // profile_icon: [
        //     {
        //         required: true,
        //         message: "Please select a profile icon",
        //         trigger: "change",
        //     },
        // ],
      },
      loading: false,
      loadingDisabled: false,
      buttonText: 'Update Team',
      foundError: false,
      tempProfileIcon: null,
      users: [],
      usersLoading: false,
      focusIndex: null
    }
  },
  computed: {
    ...mapGetters('auth', ["authUser"])
  },
  mounted() {
    this.getTeam();
  },
  methods: {
    getTeam() {
      axios.get(`/teams/${this.$route.params.id}`)
          .then(res => {
            this.formData = res.data;
          })
    },
    remoteMethod(query, index) {
      this.formData.users[index].id = null;
      this.formData.users[index].first_name = '';
      this.formData.users[index].last_name = '';
      this.formData.users[index].organization = '';
      this.formData.users[index].email = query;
      if (query !== '') {
        this.usersLoading = true;
        setTimeout(() => {
          this.callToSearchUsers(query, index);
        }, 200);
      } else {
        this.options = [];
      }
    },

    callToSearchUsers(email, index) {
      axios.get(`/users?email=${email}`)
          .then(response => {
            if (response.data.length) {
              Vue.set(this.formData.users, index, response.data[0])
            }
          })
          .finally(() => {
            this.usersLoading = false;
          });
    },

    handleSubmit(formName) {
      this.loading = true;
      console.log('formData.users before submission:', this.formData.users);

      this.checkUsersFieldsValidation();
      this.$refs[formName].validate((valid) => {
        if (valid && !this.foundError) {
          this.loadingDisabled = true;
          this.buttonText = 'Updating Team ..'
          let formData = new FormData();
          // formData.append("profile_icon", this.tempProfileIcon);
          formData.append("name", this.formData.name);
          formData.append("details", this.formData.details);
          formData.append("userTimeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);

          this.formData.users.forEach((user, key) => {
            formData.append(`users[${key}][email]`, user.email);
            formData.append(`users[${key}][first_name]`, user.first_name);
            formData.append(`users[${key}][last_name]`, user.last_name);
            formData.append(`users[${key}][organization]`, user.organization)
          });

          const config = {
            headers: {"content-type": "multipart/form-data"},
          };

          axios.post(`/teams/${this.$route.params.id}/update`, formData, config)
              .then(res => {
                this.loadingDisabled = false;
                this.buttonText = 'Update Team';
                EventBus.$emit("team-created", true);
                Notification.success('Team updated successfully');
                this.$router.push(`/team/projects?team=${res.data.team.name}&id=${res.data.team.id}&menu=projects&active=active_projects`);
              })
              .catch((error) => {
                this.loadingDisabled = false;
                this.buttonText = 'Update Team';
                if (error.response.data.errors) {
                  if (error.response.data.status === 'failed') {
                    Notification.error(error.response.data.errors);
                  } else {
                    $.each(error.response.data.errors, function (key, value) {
                      Notification.error(value[0]);
                    });
                  }

                } else {
                  Notification.error(error.response.data.message);
                }
              })
              .finally(() => {
                this.loadingDisabled = false;
                this.buttonText = 'Update Team';
                this.loading = false;
              })
        } else {
          this.loading = false;
          Notification.error('Please Fill-up the form correctly!');
          let users = this.formData.users;
          if (users.length <= 2 && users[1].email == '' && users[1].organization == '' && users[1].first_name == '' && users[1].last_name == '') {
            Notification.error('At least two members are needed for a team.');
          } else {
            Notification.error('Please Fill-up the form correctly!');
          }
          return false;
        }
      });

    },
    fileChange(file) {
      this.tempProfileIcon = file.raw;
      this.formData.profile_icon = file.raw;
    },
    addUser() {
      this.formData.users.push({
        email: "",
        first_name: "",
        last_name: "",
        organization: "",

        email_error: "",
        first_name_error: "",
        last_name_error: "",
        // organization_error: "",
      });
    },
    removeUser(index) {
      this.formData.users.splice(index, 1);
    },
    checkUsersFieldsValidation() {
      this.loading = false;
      this.foundError = false;
      this.formData.users.forEach((user, index) => {
        if (user.email === "") {
          this.setValidationMessage(index, 'email_error')
        }
        if (user.first_name === "" || user.first_name === null) {
          this.setValidationMessage(index, 'first_name_error')
        }
        if (user.last_name === "" || user.last_name === null) {
          this.setValidationMessage(index, 'last_name_error')
        }
        // if (user.organization === "") {
        //   this.setValidationMessage(index, 'organization_error')
        // }
      });
    },
    setValidationMessage(index, field) {
      this.formData.users[index][field] = "This field is required";
      this.foundError = true;
    }
  },
  watch: {
    'foundError': {
      handler(value) {
        if (!value) {
          this.formData.users.forEach((user, index) => {
            this.formData.users[index].email_error = "";
            this.formData.users[index].first_name_error = "";
            this.formData.users[index].last_name_error = "";
            // this.formData.users[index].organization_error = "";
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>